// Import global css file
import './scss/app.scss';

/** ------------------------------*/
// GLOBAL VARIABLES
/** ------------------------------*/
//const navItems = Array.prototype.slice.call(document.querySelectorAll('.item-has-child'));


// mobile menu toggle and calculate top position of menu

function menuToggle() {
  const menuOpen = document.querySelector('.site-header__burger');
  const mainMenu = document.querySelector('.site-header__menu');

  menuOpen.addEventListener('click', () => {

    if (!mainMenu.classList.contains('active')) {
      mainMenu.classList.add('active');
      document.body.classList.add("open");
    }
    else {
      mainMenu.classList.remove('active');
      document.body.classList.remove("open");
    }
  });

}

menuToggle();


// sub menu toggle logic

// var mobileNavLogic = function (array,index) {
//   array[index].children[1].addEventListener('click', function () {
//       navItems.forEach(function(navItem,it) {
//         var itemChild = navItem.children[2];

//           if(it === index) {
//               if(!navItem.classList.contains('active')) {
//                 navItem.classList.add('active');
//                 if(itemChild) {
//                   itemChild.classList.add('active');
//                 }
//             }
//             else {
//                 navItem.classList.remove('active');
//                 if(itemChild) {
//                   itemChild.classList.remove('active');
//                 }
//             }
//           }
//       });
//   });
// }

// chevron size logic for mobile
// function navItemWithChildren() {

//   if(navItems) {
//     navItems.forEach(function (navItem){
//       const itemWidth = navItem.offsetWidth;
//       const anchorWidth = navItem.children[0].offsetWidth;
//       const spanTag = navItem.children[1];
//       const navItemSecondChild = navItem.querySelector('.child-menu .item-has-child');

//       spanTag.style.width = itemWidth -  anchorWidth - 100 + 'px';

//       if(navItemSecondChild) {
//         const secondChildSpan = navItemSecondChild.children[1];
//         secondChildSpan.style.width = itemWidth -  anchorWidth - 100 + 'px';
//       }
//     });
//   }
// }

// if (window.outerWidth < 769) {
//   navItemWithChildren();

//   for(var i = 0, len = navItems.length; i < len; i++) {
//     mobileNavLogic(navItems,i);
//   }
// }

function scrolldownClick() {
  let clickedElement = document.querySelectorAll(".hero__scrollDown");
  if (clickedElement) {
    const headerHeight = document.querySelectorAll('.site-header')[0].clientHeight;
    const windowHeight = window.innerHeight;
    const scrollHeight = windowHeight - headerHeight;
    for (let i = 0; i < clickedElement.length; i++) {
      clickedElement[i].addEventListener('click', () => window.scrollTo({
        top: scrollHeight,
        behavior: 'smooth',
      }));
    }
  }
}

scrolldownClick()

function accordianFunction() {
  let textDropdownHeading = document.querySelectorAll(".text__dropdown--heading");
  for (let i = 0; i < textDropdownHeading.length; i++) {
    textDropdownHeading[i].addEventListener("click", function(){
      if (this.parentElement.classList.contains('active')) {
        this.parentElement.classList.remove('active');
      }
      else {
        if (document.querySelectorAll('.text__dropdown.active').length) {
          document.querySelectorAll('.text__dropdown.active')[0].classList.remove('active');
        }
        this.parentElement.classList.add('active');
      }
    });
  }
}

accordianFunction();

function popupLogic() {
  if (document.querySelectorAll('.popupButton')) {
    const popupButtonEl = document.querySelectorAll('.popupButton');
    const closeBTN = document.querySelectorAll('.popup-close');
    for (let i = 0; i < popupButtonEl.length; i++) {
      popupButtonEl[i].addEventListener("click", (e) => {
        e.preventDefault();
        let clickedEl = popupButtonEl[i].dataset.id;
        let popupID = document.getElementById(clickedEl);
        popupID.classList.add("active");
        document.body.classList.add("open");
        // Initiate slider once popup is closed
        let popupCarousel = document.querySelectorAll(".popup.active .popup-content-gallery");
        if (popupCarousel.length) {
          let glide = new Glide('.active .popup-content-gallery', {
            autoplay: 3000,
            hoverpause: true,
            perView: 4,
            gap: 0,
            //bound: true,
            breakpoints: {
              1200: {
                perView: 3,
              },
              800: {
                perView: 3,
              },
              500: {
                perView: 1.5,
              },
            },
          });
          glide.mount();
          // Destroy slider once popup is closed
          document.querySelectorAll(".popup.active .popup-close")[0].addEventListener("click", function(){
            glide.destroy();
          });
        }
      });
      for (let a = 0; a < closeBTN.length; a++) {
        closeBTN[a].addEventListener("click", function(){
          this.parentNode.parentNode.classList.remove("active");
          document.body.classList.remove("open");
        });
      }
    }
  }
}

popupLogic();

function injectVideoScript(src, cb) {
  // don't inject script twice - check if exists
  if (document.getElementById('vimeo-video-script')) {
    return false;
  }
  // create script tag
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'vimeo-video-script';
  script.async = true;
  script.defer = true;
  script.dataset.otIgnore = true;
  // set cb states
  script.onload = function () {
    return cb(true);
  };
  script.onerror = function (e) {
    return cb(false, e);
  };
  script.src = src;
  document.getElementsByTagName('head')[0].appendChild(script);
}

function videoBlockPlay() {
  iframeContentBlockStyling();
  let playButton = Array.prototype.slice.call(document.querySelectorAll(".video__play"));
  injectVideoScript('https://player.vimeo.com/api/player.js', function () {
    if (playButton.length > 0) {
      for (let i = 0; i < playButton.length; i++) {
        playButton[i].addEventListener("click", () => {
          // Remove active from other elements
          if (Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active")).length) {
            let activeVideos = Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active"));
            for (let a = 0; a < activeVideos.length; a++) {
              activeVideos[a].classList.remove('active');
              let iframeElement = activeVideos[a].nextElementSibling;
              /* eslint-disable */
              let vimeo_options = {
                controls: true
              }
              let player = new Vimeo.Player(iframeElement, vimeo_options);
              player.unload();
              /* eslint-enable */
            }
          }
          playButton[i].parentNode.classList.add("active");
          let iframeElement = playButton[i].parentNode.nextElementSibling;
          /* eslint-disable */
          let vimeo_options = {
            controls: true
          }
          let player = new Vimeo.Player(iframeElement, vimeo_options);
          player.play();
          player.on('pause', function(){
            playButton[i].parentNode.classList.remove("active");
          });
          /* eslint-enable */
          // Close and pause video on scroll
          window.addEventListener('scroll', () => {
            if (Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active").length)) {
              let activePlayers = Array.prototype.slice.call(document.querySelectorAll(".video__overlay.active"));
              for (let a = 0; a < activePlayers.length; a++) {
                let iframeElement = activePlayers[a].nextElementSibling;
                /* eslint-disable */
                let vimeo_options = {
                  controls: false
                }
                let player = new Vimeo.Player(iframeElement, vimeo_options);
                player.pause();
                activePlayers[a].classList.remove("active");
                /* eslint-enable */
              }
            }
          });
        });
      }
    }
  })
}

videoBlockPlay();

function formValidation() {
  const formElement = document.querySelectorAll('.contact-form button');
  const form = document.querySelectorAll(".contact-form")[0];
  if (formElement) {
    for (let i = 0; i < formElement.length; i++) {
      formElement[i].addEventListener("click", (e) => {
        e.preventDefault();
        let inputFields = Array.prototype.slice.call(document.querySelectorAll('.contact-form input'));
        for (let a = 0; a < inputFields.length; a++) {
          if (inputFields[a].required) {
            if (inputFields[a].name === 'emailAddress') {
              let emailField = inputFields[a].value;
              if (emailField.length < 1) {
                inputFields[a].parentElement.classList.add("error");
              }
              else {
                let regEx = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                let validEmail = regEx.test(emailField);
                if (!validEmail) {
                  inputFields[a].parentElement.classList.add("error");
                }
                else {
                  inputFields[a].parentElement.classList.remove("error");
                }
              }
            }
            else if (inputFields[a].getAttribute('type') === 'checkbox') {
              if (inputFields[a].checked) {
                inputFields[a].parentElement.classList.remove("error");
              }
              else {
                inputFields[a].parentElement.classList.add("error");
              }
            }
            else {
              if (inputFields[a].value < 1) {
                inputFields[a].parentElement.classList.add("error");
              }
              else {
                inputFields[a].parentElement.classList.remove("error");
              }
            }
          }
        }
        let enquiry = document.getElementById("enquiry");
        if (enquiry.value.length < 5) {
          enquiry.parentElement.classList.add("error");
        }
        else {
          enquiry.parentElement.classList.remove("error");
        }
        let errorFields = Array.prototype.slice.call(document.querySelectorAll('.error'));
        if (errorFields.length > 0) {
          e.preventDefault();
        }
        else {
          form.submit();
        }
      });
    }
  }
}

formValidation();

function iframeContentBlockStyling() {
  const iframeElements = document.querySelectorAll('.contentBlock__fullWidth iframe');
  if (iframeElements) {
    for (let i = 0; i < iframeElements.length; i++) {
      iframeElements[i].parentNode.classList.add("content__video");
      const el = document.createElement("div");
      el.classList.add("video__overlay");
      iframeElements[i].parentNode.prepend(el);
      iframeElements[i].previousSibling.innerHTML = '<div class="video__play"><img src="/themes/tommeetippee/assets/img/play.svg" width = "42" height = "76" alt = "" ></div>';
    }
  }
}

import Glide from '@glidejs/glide';
function imageCarousel() {
  let imageCarousel = document.querySelectorAll('.glide');
  if (imageCarousel.length) {
    let glide = new Glide('.glide', {
      autoplay: 3000,
      hoverpause: true,
      perView: 2,
      gap: -2,
      bound: true,
    });

    glide.mount();
  }
}
imageCarousel();

function imageCarouselTile() {
  let imageCarousel = document.querySelectorAll('.glide-tile');
  if (imageCarousel.length) {
    let glide = new Glide('.glide-tile', {
      autoplay: 5000,
      hoverpause: true,
      perView: 3,
      type: 'carousel',
      gap: 50,
      peek: {
        before: 20,
        after: 20,
      },
      breakpoints: {
        767: {
          perView: 1,
          gap: 30,
          peek: {
            before: 20,
            after: 20,
          },
        },
      },
    });

    glide.mount();
  }
}
imageCarouselTile();

// function internalLinkScroll(){
//   let tabLink = document.querySelectorAll('.tabbedNav--list li a');
//   if (tabLink) {
//     let headerOffset = document.querySelector(".site-header").offsetHeight;
//     document.querySelectorAll('.tabbedNav--list li a').forEach(link => {
//       link.addEventListener('click', (e) => {
//         e.preventDefault();
//         let href = link.getAttribute("href");
//         let element = document.querySelectorAll(href);
//         let elementPosition = element[0].getBoundingClientRect().top;
//         let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
//         window.scrollTo({
//           top: offsetPosition,
//           behavior: "smooth",
//         });
//       });
//     });
//   }
// }
// internalLinkScroll();

function achievementsCarousel() {
  const glideContent = document.querySelectorAll('.contentBlock__tabbedContent .glide-content');
  if (glideContent) {
    const conf = {
      autoplay: 5000,
      hoverpause: true,
      perView: 3,
      type: 'slider',
      gap: 50,
      peek: {
        before: 20,
        after: 20,
      },
      breakpoints: {
        767: {
          perView: 1,
          gap: 30,
          peek: {
            before: 20,
            after: 20,
          },
        },
      },
    }
    if (glideContent) {
      glideContent.forEach(item => {
        new Glide(item, conf).mount()
      })
    }
  }
}

achievementsCarousel();

function downloadsCarousel() {
  const glideContent = document.querySelectorAll('.carousel-slides--downloads');
  if(glideContent) {
    const conf = {
      autoplay: 4000,
      rewind: false,
      animationDuration: 800,
      hoverpause: true,
      startAt: 0,
      perView: 4,
      type: 'slider',
      gap: 40,
      // peek: {
      //   before: 75,
      //   after: 75,
      // },
      bound: true,
      breakpoints: {
        767: {
          perView: 1,
          gap: 30,
          // peek: {
          //   before: 20,
          //   after: 20,
          // },
        },
      },
    }
    if (glideContent) {
      glideContent.forEach(item => {
        new Glide(item, conf).mount()
      })
    }
  }
}

downloadsCarousel();

function pressCarousel() {
  const glideContent = document.querySelectorAll('.carousel-slides--press');
  if(glideContent) {
    const conf = {
      autoplay: 4000,
      rewind: true,
      animationDuration: 800,
      hoverpause: true,
      startAt: 0,
      perView: 3,
      type: 'slider',
      gap: 40,
      bound: true,
      breakpoints: {
        768: {
          perView: 2,
          gap: 30,
        },
        600: {
          perView: 1,
          gap: 30,
        },
      },
    }
    if (glideContent) {
      glideContent.forEach(item => {
        const countPress = item.querySelectorAll('div.press-item');
        let maxDeviceItems = 3;
        if (window.matchMedia("(max-width: 768px)").matches) {
          maxDeviceItems = 2;
        }
        if (window.matchMedia("(max-width: 600px)").matches) {
          maxDeviceItems = 1;
        }
        if(countPress.length > maxDeviceItems) {
          new Glide(item, conf).mount()
        }
      })
    }
  }
}

pressCarousel();

const locationSliderConf = {
  autoplay: 4000,
  rewind: true,
  animationDuration: 800,
  hoverpause: true,
  startAt: 0,
  perView: 3,
  perTouch: 1,
  type: 'slider',
  gap: 40,
  bound: true,
  breakpoints: {
    767: {
      perView: 1,
      gap: 0,
      peek: 0,
    },
  },
}

function locationsShowHide() {
  const contentBlockGlobal = document.querySelectorAll(".contentBlock__global");
  if (contentBlockGlobal) {
    const glideLocations = document.querySelectorAll('.locations .glide-locations');
    let glideInstances = [];
    if (glideLocations) {
      glideLocations.forEach(item => {

        const countLocations = item.querySelectorAll('li.location');
        let maxDeviceItems = 3;
        if (window.matchMedia("(max-width: 768px)").matches) {
          maxDeviceItems = 2;
        }
        if (window.matchMedia("(max-width: 600px)").matches) {
          maxDeviceItems = 1;
        }
        if(countLocations.length > maxDeviceItems) {
          glideInstances[item.dataset.sliderId] = new Glide(item, locationSliderConf);
        }
      })
    }

    // catch any active items when we load.
    const activeLocation = document.querySelectorAll('.locations.active .glide-locations');
    if (activeLocation) {
      activeLocation.forEach(item => {
        const countLocations = item.querySelectorAll('li.location');
        if(countLocations.length > 3) {
          glideInstances[item.dataset.sliderId].mount();
        }
      })
    }

    const locationIems = document.querySelectorAll(".contentBlock__global .row ul.location-list li.item");
    locationIems.forEach(item => {
      item.addEventListener("click", () => {
        let locationClicked = item.dataset.location;

        if (!document.getElementById("location-" + locationClicked).classList.contains("active")) {
          if (document.querySelectorAll(".locationsListing .locations.active").length < 1) {
            document.getElementById("location-" + locationClicked).classList.add("active");
            if(glideInstances['continent-' + locationClicked] !== undefined) {
              glideInstances['continent-' + locationClicked].mount();
            }
          }
          else {
            document.querySelectorAll(".locationsListing .locations.active").forEach(activeItem => {
              activeItem.classList.remove('active');
              if(glideInstances['continent-' + locationClicked] !== undefined) {
                glideInstances['continent-' + locationClicked].destroy();
              }
            });
            document.getElementById("location-" + locationClicked).classList.add("active");
            if(glideInstances['continent-' + locationClicked] !== undefined) {
              glideInstances['continent-' + locationClicked].mount();
            }
          }
        }
        else {
          document.getElementById("location-" + locationClicked).classList.remove("active");
          if(glideInstances['continent-' + locationClicked]) {
            glideInstances['continent-' + locationClicked].destroy();
          }
        }
      })
    });
  }
}
locationsShowHide();

function cardCarousels() {
  const glideContent = document.querySelectorAll('.cards-carousel');
  if (glideContent) {
    const conf = {
      autoplay: 5000,
      rewind: true,
      hoverpause: true,
      perView: 1,
      type: 'slider',
      gap: 0,
    }
    if (glideContent) {
      glideContent.forEach(item => {
        new Glide(item, conf).mount()
      })
    }
  }
}

if (window.matchMedia("(max-width: 600px)").matches) {
  // The viewport is less than 768 pixels wide
  cardCarousels();
}

function awardsCarousel() {
  const glideContent = document.querySelectorAll('.awards-carousel');
  if (glideContent) {
    const conf = {
      autoplay: 4000,
      rewind: true,
      animationDuration: 800,
      hoverpause: true,
      startAt: 0,
      perView: 4,
      type: 'slider',
      gap: 40,
      bound: true,
      breakpoints: {
        767: {
          perView: 1,
          gap: 0,
          peek: 0,
        },
      },
    }
    if (glideContent) {
      glideContent.forEach(item => {
        new Glide(item, conf).mount()
      })
    }
  }
}

// The viewport is less than 768 pixels wide
awardsCarousel();

function cardAchievementsCarousels() {
  const glideContent = document.querySelectorAll('.tabbed-cards-carousel');
  if (glideContent) {
    const conf = {
      autoplay: 4000,
      rewind: true,
      animationDuration: 800,
      hoverpause: true,
      startAt: 0,
      perView: 3,
      type: 'slider',
      gap: 40,
      bound: true,
      breakpoints: {
        767: {
          perView: 1,
          gap: 20,
          peek: 0,
        },
      },
    }
    if (glideContent) {
      glideContent.forEach(item => {
        const countAchievements = item.querySelectorAll('div.card');
        let maxDeviceItems = 3;
        if (window.matchMedia("(max-width: 768px)").matches) {
          maxDeviceItems = 2;
        }
        if (window.matchMedia("(max-width: 600px)").matches) {
          maxDeviceItems = 1;
        }
        if(countAchievements.length > maxDeviceItems) {
          new Glide(item, conf).mount()
        }
      })
    }
  }
}

cardAchievementsCarousels();

function tabbedNavRevealPanels() {
  const tabbedNav = document.querySelectorAll('.tabbedNav');
  if (tabbedNav.length > 0) {
    tabbedNav.forEach(item => {
      const tabbedNavItems = item.querySelectorAll('.tabbedNav--list .internal-link');
      tabbedNavItems.forEach(item => {
        // Add click event to each item
        item.addEventListener("click", (e) => {
          e.preventDefault();

          // Get the panel ID from the link being clicked.
          const tabbedNavId = item.dataset.panelId;
          // Get the panel to be made active.
          const tabbedNavPanel = document.getElementById(tabbedNavId);
          // Loop existing active panels and remove active state.
          const activePanels = document.querySelectorAll(`.tabbedNav--panels.active`);
          activePanels.forEach((activePanel) => {
            activePanel.classList.remove('active');
          });

          // Activate the correct panel.
          tabbedNavPanel.classList.add('active');

          // if we have a carousel in the tab, initiate it.
          const hasCarousel = tabbedNavPanel.querySelector('.tabbed-cards-carousel');
          if(hasCarousel) {
            cardAchievementsCarousels();
          }

          // Scroll to the element on mobile.
          if (window.matchMedia("(max-width: 767px)").matches) {
            let headerOffset = document.querySelector(".site-header").offsetHeight;
            let element = document.querySelectorAll(`#${tabbedNavId}.active`);
            let elementPosition = element[0].getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        })
      })
    })
  }
}

tabbedNavRevealPanels();
